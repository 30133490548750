<template id="admin-payments">
  <section>
    <div class="d-flex justify-content-between align-items-center align-content-center">
      <h4>Payments</h4>
      <b-button variant="info" size="sm" @click.prevent="loadpayments">
        <font-awesome-icon :icon="['far','sync']"></font-awesome-icon>
        Refresh
      </b-button>
    </div>
    <div class="d-flex justify-content-lg-between flex-column flex-md-row">

      <b-form-select class="mr-0 mr-md-3 w-100 w-md-50 mt-3" @change="loadpayments" v-model="area">
        <option value="0">All Apartments</option>
        <option v-for="a of $store.state.apartments" :key="a.id" :value="a.id">{{ a.name }}</option>
      </b-form-select>
      <b-form-select class="mr-0 mr-md-3 w-100 w-md-50 mt-3" @change="loadpayments" v-model="status">
        <option value="ALL">ALL</option>
        <option value="UN_VERIFIED">Verification Pending</option>
        <option value="VERIFIED">Verified</option>
      </b-form-select>
      <b-form-select v-model="s.dateRange"
                     class="mr-0 mr-md-3 w-100 w-md-50 mt-3"
                     @change="loadpayments">
        <option value="THIRTY">Last 30 Days</option>
        <option value="SIXTY">Last 60 Days</option>
        <option value="NINETY">Last 90 Days</option>
      </b-form-select>
      <b-form-input type="text" class="mr-0 mr-md-3 w-100 w-md-50 mt-3" v-model="filter" placeholder="Search"/>

    </div>
    <div class="card-columns mt-3">
      <div class="card bg-transparent" v-for="(p, idx) of filteredPayments" v-bind:key="idx">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <div>{{ p.customer.name }}, {{ p.customer.house }}
              <span class="text-success" v-if="p.verified"><font-awesome-icon
                  :icon="['far','check-square']"></font-awesome-icon></span>
              <span class="text-warning" v-if="!p.verified">
                <font-awesome-icon :icon="['far','exclamation-square']"></font-awesome-icon>
                </span>
            </div>

            <div class="text-muted">
              <font-awesome-icon :icon="['far','calendar']"></font-awesome-icon>
              {{ p.order.orderDateStr }}

            </div>
          </div>

        </div>
        <div class="list-group">
          <div class="list-group-item">
            <rupee>{{ p.order.netValue }}</rupee> (order value)
          </div>
          <div class="list-group-item">
            <span v-if="editPayment !== p.id"><font-awesome-icon :icon="['far','tag']"></font-awesome-icon> {{ p.modeOfPayment }}</span>
            <b-form-group label="Mode Of Payment" v-if="editPayment === p.id">
              <select v-model="p.modeOfPayment" class="form-control">
                <option value="CASH">CASH</option>
                <option value="PAYTM">PAYTM</option>
                <option value="GPAY">GPAY</option>
                <option value="PHONEPE">PHONEPE</option>
                <option value="UPI">UPI</option>
                <option value="RAZORPAY">RAZORPAY</option>
              </select>
            </b-form-group>
          </div>
          <div class="list-group-item">
                        <span v-if="editPayment !== p.id" class="text-danger">
                           &ndash; <rupee>{{ p.deductions }}</rupee> (deductions)
                        </span>
            <b-form-group label="Deductions" v-if="editPayment === p.id">
              <input type="number" class="form-control" v-model="p.deductions"
                     @input="updateDeduction(p)"/>
            </b-form-group>

          </div>
          <div class="list-group-item text-danger" v-if="p.wallet">
            - <rupee>{{p.walletAmt}}</rupee> (Wallet)
          </div>
          <div class="list-group-item">
                        <span v-if="editPayment !== p.id" class="text-danger">
                           &ndash; <rupee>{{ p.adjustments }}</rupee> (adjustments)
                        </span>
            <b-form-group label="Adjustments" v-if="editPayment === p.id">
              <input type="number" class="form-control" v-model="p.adjustments"
                     @input="updateDeduction(p)"/>
            </b-form-group>
          </div>
          <div class="list-group-item">
                        <span class="text-success">
                            <rupee>{{ p.amount }}</rupee> (paid amount)
                        </span>
          </div>
          <div class="list-group-item" v-if="p.screenshot">
            <b-img-lazy :src="makeImgUrl(p.screenshot)" fluid></b-img-lazy>
          </div>
        </div>
        <div class="card-footer text-right">
         <div class="btn-group btn-group-sm">
           <button class="btn btn-sm btn-info" @click.prevent="editPayment = p.id" v-if="editPayment !== p.id">
             <font-awesome-icon :icon="['far','edit']"></font-awesome-icon>
             Edit
           </button>
           <button class="btn btn-sm btn-success" @click.prevent="savePayment(p)" v-if="editPayment === p.id">
             <font-awesome-icon :icon="['far','save']"></font-awesome-icon>
             Save
           </button>
           <button class="btn btn-sm" @click.prevent="verifyPayment(p)"
                   v-bind:class="{'btn-primary': !p.verified, 'btn-secondary': p.verified}">
             <font-awesome-icon :icon="['far','check']"></font-awesome-icon>
             <span v-if="!p.verified">Verify</span>
             <span v-if="p.verified">Verified</span>
           </button>
         </div>
        </div>
      </div>

    </div>
  </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'

export default {
  name: 'AdminPayments',
  components: {Rupee},
  data: () => ({
    payments: [],
    editPayment: null,
    filter: '',
    area: '0',
    status: 'UN_VERIFIED',
    cat: {},
    s: {
      dateRange: 'THIRTY'
    }
  }),
  created: function () {
    this.loadpayments()
  },
  computed: {
    filteredPayments: function () {
      let f = this.filter.toLowerCase()
      if (f === '') return this.payments
      else return this.payments.filter(p => p.customer.name.toLowerCase().indexOf(f) > -1
          || p.customer.house.toLowerCase().indexOf(f) > -1)
    }
  },
  methods: {
    makeImgUrl(p) {
      return `${process.env.VUE_APP_URL}/public/image/${p}`
    },
    async loadpayments() {
      let json = await axios.get(`/api/admin/payments?time=${this.s.dateRange}&status=${this.status}&area=${this.area}`)
      this.payments = json.data
    },
    updateDeduction: function (p) {
      p.amount = p.order.netValue - p.deductions - p.adjustments
    },
    async savePayment(p) {
      await axios.post('/api/admin/payments', p)
      this.editPayment = null
    },
    async verifyPayment(p) {
      await axios.put(`/api/admin/payments/verify/${p.id}`)
      p.verified = true
    }
  }
}
</script>
<style>
</style>