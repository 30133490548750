<template id="admin-products">
  <section>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
      <h4>Products</h4>
      <div class="d-flex justify-content-md-end flex-column flex-md-row">
        <div class="mb-3 ml-md-3">
          <b-form-select v-model="cat">
            <b-form-select-option :value="null">Show All</b-form-select-option>
            <b-form-select-option v-for="c of $store.state.categories" :value="c.id" :key="c.id">{{ c.name }}
            </b-form-select-option>
          </b-form-select>
        </div>
        <div class="mb-3 ml-md-3">
          <b-form-input type="text" placeholder="Search" v-model="filter" debounce="500"/>
        </div>
        <div class="mb-3 ml-md-3">
          <b-form-select v-model="imgFilter">
            <option value="ALL">With & Without Pictures</option>
            <option value="ONLY">Has Pictures</option>
            <option value="MISSING">Without Pictures</option>
          </b-form-select>
        </div>
        <div class="mb-3 ml-md-3">

          <b-form-select v-model="videoFilter">
            <option value="ALL">With & Without Videos</option>
            <option value="ONLY">Has Videos</option>
            <option value="MISSING">Without Videos</option>
          </b-form-select>
        </div>
        <div class="mb-3 ml-md-3">
          <new-product :product="product" :edit="false">
            <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
            New Product
          </new-product>
        </div>
      </div>
    </div>

    <div class="card-columns">
      <admin-product-view
          v-for="p of filteredProducts"
          :key="p.id"
          :product="p"
      ></admin-product-view>
    </div>
  </section>
</template>
<script>
import NewProduct from '@/components/new-product'
import AdminProductView from '@/components/admin-product-view'
import _ from 'lodash'

export default {
  name: 'AdminProducts',
  components: {AdminProductView, NewProduct},
  data: () => ({
    filter: '',
    edit: false,
    imgFilter: 'ALL',
    videoFilter: 'ALL',
    cat: null,

    product: {
      category: {id: null},
      taxPct: {id: null},
      moq: 1,
      mainImage: '',
      variants: {id: null},
      subTitle: '',
      ingredients: [],
      images: []
    },
    recipe: {
      recipe: []
    },
    activeVariants: [],
    selectedVariants: []
  }),
  async created() {
    await this.$store.dispatch('loadProdCat')
    await this.$store.dispatch('loadProductTags')
    await this.$store.dispatch('loadIngredients')
  },
  computed: {
    singleVariantProduct: function () {
      return this.p.variants.length === 1
    },
    filteredProducts: function () {
      let f = this.filter.toLowerCase()
      let f1 = []

      let allProducts = this.allProducts
      if (f === '') f1 = allProducts
      else f1 = allProducts.filter(p => p.name.toLowerCase().indexOf(f) > -1)

      let f2 = f1
      if (this.imgFilter === 'MISSING') {
        f2 = f1.filter(p => p.images.length === 0)
      }
      if (this.imgFilter === 'ONLY') {
        f2 = f1.filter(p => p.images.length > 0)
      }
      if (this.videoFilter === 'MISSING') {
        f2 = f1.filter(p => p.videos.length === 0)
      }
      if (this.videoFilter === 'ONLY') {
        f2 = f1.filter(p => p.videos.length > 0)
      }
      if (this.cat !== null) f2 = f2.filter(p => p.category.id === this.cat)
      return _.sortBy(_.take(f2, 20), 'name')
    },
    allProducts() {
      return this.$store.state.products
    },
  },
  methods: {

  }
}
</script>
<style>
</style>