<template id="admin-customers">
  <section>
    <div class="d-flex justify-content-start flex-column flex-md-row mb-md-2 mr-md-3">
      <h4 class="text-center text-md-left">Customers</h4>
      <div class="d-flex flex-column flex-md-row flex-fill justify-content-end">
        <div class="d-flex flex-column flex-md-row ">
          <b-form-input type="text" placeholder="Search"
                        class="mr-md-2 mb-2 mb-md-0"
                        v-model="filter" debounce="100" @input="loadCustomers"
                        @change="loadCustomers"/>
          <b-form-select v-model="filterArea" @input="loadCustomers" :options="areas"
                         value-field="id"
                         class="mr-md-2 mb-2 mb-md-0"
                         text-field="displayName"></b-form-select>
          <b-form-select v-model="brandFilter"
                         @input="loadCustomers"
                         class="mr-md-2 mb-2 mb-md-0"
                         :options="allBrands"
                         value-field="id" text-field="name"></b-form-select>


        </div>
        <b-button-group size="sm" class="mb-3 ml-md-3 ml-md-2">
          <b-button
              size="sm"
              variant="danger"
              @click="clearSearch">
            <font-awesome-icon icon="times"></font-awesome-icon>
            Clear
          </b-button>
          <b-button @click="createNewCustomer" variant="info">
            <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
            <span class="ml-1 d-none d-md-inline">New Customer</span>
            <span class="ml-1 d-inline d-md-none">New</span>
          </b-button>
          <b-button variant="primary" @click="messageAll()" :disabled="!areaSelected">
            <font-awesome-icon :icon="['far','mail-bulk']"></font-awesome-icon>
            <span class="ml-1 d-none d-md-inline">Message All Customers</span>
            <span class="ml-1 d-inline d-md-none">Msg Cust</span>
          </b-button>
          <b-button variant="success" @click="messageAllLeads()">
            <font-awesome-icon :icon="['far','mail-bulk']"></font-awesome-icon>
            <span class="ml-1 d-none d-md-inline">Message All Leads</span>
            <span class="ml-1 d-inline d-md-none">Msg Lead</span>
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row flex-wrap">
      <div class="card bg-transparent mt-3 mr-md-3" v-for="c of customers" :key="c.id">
        <div class="card-header d-flex justify-content-start align-items-center align-content-center">
          <customer-avatar :customer="c" size="2rem"></customer-avatar>
          <span class="ml-2">{{ c.name }}</span>
          <div class="d-flex justify-content-end flex-fill align-content-center align-items-center">
            <display-brand v-if="c.brand" :id="c.brand.id" :border="false" :name="false"></display-brand>
            <div class="ml-1">
              <dont-disturb :customer="c"></dont-disturb>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex flex-column">

              <span v-if="c.deliveryArea">
                <font-awesome-icon :icon="['far','building']"></font-awesome-icon>
                {{ c.deliveryArea.name }}
              </span>

            <div class="mt-3 text-right">
              <b-form-checkbox
                  :id="'review-checkbox-'+c.id"
                  @change="updateReviewStatus(c)"
                  v-model="c.reviewDone"
                  :name="'review-checkbox-'+c.id"
                  :value="true"
                  :unchecked-value="false"
              >
                Review Done
              </b-form-checkbox>

            </div>

          </div>
          <div class="d-flex justify-content-end mt-3">
            <b-button @click="copyToClipboard(c.mobile)" size="sm" variant="warning">
              <font-awesome-icon icon="phone"></font-awesome-icon>
              <span class="ml-2">{{ c.mobile }}</span>
            </b-button>
          </div>
        </div>
        <div class="card-footer">
          <b-button-group>
            <button class="btn btn-success" @click="newOrder(c)">
              <font-awesome-icon icon="plus"></font-awesome-icon>
            </button>
            <button class="btn btn-primary" @click="openDetailedPage(c)">
              <font-awesome-icon :icon="['far','eye']"></font-awesome-icon>
            </button>
            <button class="btn btn-info" @click.prevent="editCustomer(c)">
              <font-awesome-icon :icon="['far','edit']"></font-awesome-icon>
            </button>
            <button class="btn btn-success" @click.prevent="editCustomerAddress(c)">
              <font-awesome-icon :icon="['far','building']"></font-awesome-icon>
            </button>
            <b-button variant="warning" @click="openTel(c)">
              <font-awesome-icon :icon="['fas','phone']"></font-awesome-icon>
            </b-button>
            <b-button variant="success" @click.prevent="sendMessage(c)">
              <font-awesome-icon :icon="['fab','whatsapp']"></font-awesome-icon>
            </b-button>
            <b-button variant="primary" @click.prevent="createWallet(c)">
              <font-awesome-icon :icon="['far','money-check']"></font-awesome-icon>
            </b-button>
            <b-button variant="info" @click.prevent="walletHistory(c)">
              <font-awesome-icon :icon="['far','money-check']"></font-awesome-icon>
            </b-button>
          </b-button-group>
        </div>
      </div>
    </div>
    <b-modal id="modal-wallets" title="Customer's Wallet" hide-footer size="lg">

      <b-form-group label="Customer">
        <b-form-input v-model="newCustomer.name" disabled></b-form-input>
      </b-form-group>

      <b-form-group label="Amount">
        <b-form-input v-model="wallets.available" type="number" disabled></b-form-input>
      </b-form-group>

      <h4>Transactions</h4>
      <table class="table table-borderless">
        <thead>
        <tr>
          <th>Date</th>
          <th>Brand</th>
          <th>Type</th>
          <th>Remarks</th>
          <th>Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="w of wallets.transactions" :key="w.id">
          <td>{{ displayDate(w.transactionDate) }}</td>
          <td>
            <display-brand v-if="w.brand" :id="w.brand.id" :border="false" :name="false"></display-brand>
          </td>
          <td>{{ w.transactionType }}</td>
          <td>{{ w.transactionRemarks }}</td>
          <td v-bind:class="{'text-danger': w.amount < 0, 'text-success': w.amount > 0}">{{ w.amount }}</td>
        </tr>
        </tbody>
      </table>

    </b-modal>
    <b-modal id="modal-wallet" title="Add Money to Customer's Wallet"
             :ok-disabled="!brandId"
             @ok="saveWallet">

      <b-form-group label="Customer">
        <b-form-input v-model="newCustomer.name" disabled></b-form-input>
      </b-form-group>

      <b-form-group label="Amount">
        <b-form-input v-model="wallet.amount" type="number"></b-form-input>
      </b-form-group>
      <b-form-group label="Remarks">
        <b-form-input v-model="wallet.remarks" type="text"></b-form-input>
      </b-form-group>
      <b-form-group label="Brand">
        <b-form-select v-model="brandId" :options="$store.state.brands"
                       value-field="id"
                       text-field="name"></b-form-select>
      </b-form-group>
    </b-modal>

    <b-modal id="broadcast" :title="broadcastTitle" @ok="sendBroadcast" :ok-disabled="!brandId">

      <b-form-group label="Brand">
        <b-form-select :options="$store.state.brands" value-field="id" text-field="name"
                       v-model="brandId"></b-form-select>
      </b-form-group>

      <div class="alert alert-info" v-if="whom === 'CUSTOMER'">
        if the text contains <strong>-name-</strong>, then it will be replaced with the customers name
      </div>

      <b-form-group label="Message (5 products in 5 lines)">
        <b-form-textarea v-model="mesg" rows="8"></b-form-textarea>
      </b-form-group>


      <b-form-select v-model="when">
        <b-form-select-option v-for="day in days" :key="day" :value="day">{{day}}</b-form-select-option>
      </b-form-select>


      <b-form-file v-model="files" multiple placeholder="Select Files to Send" class="mt-3 mb-3"></b-form-file>

      <div class="d-flex justify-content-between flex-column flex-md-row my-3" v-if="files.length > 0">

        <div class="d-flex flex-column">
          <span>{{ files.length }} Files are Selected</span>
          <span v-for="(f, idx) of files" :key="idx">{{ f.name }}</span>
        </div>


        <div class="d-flex justify-content-end">
          <b-button @click.prevent="clearFiles" size="sm" variant="warning">
            Clear Files
          </b-button>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <b-button-group size="sm" v-if="whom === 'CUSTOMER'">
          <b-button @click="selectAll(true)" variant="success">
            Select ALL
          </b-button>
          <b-button @click="selectAll(false)" variant="danger">
            Remove ALL
          </b-button>
        </b-button-group>
      </div>

      <div class="list-group mt-3">
        <div class="list-group-item d-flex justify-content-between" v-for="c of send" :key="c.id">
          <span>{{ c.name }} ({{ c.phone }})</span>
          <b-form-checkbox
              :id="`checkbox-${c.id}`"
              v-model="c.send"
              :name="`checkbox-${c.id}`"
              :value="true"
              switch
              :unchecked-value="false">
            Send
          </b-form-checkbox>

        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import CustomerAvatar from './customer-avatar'
import { NewCustomer, NewOrder } from '@/util'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import DisplayBrand from '@/components/display-brand'
import DontDisturb from '@/components/others/DontDisturb'

export default {
  name: 'AdminCustomers',
  components: {DontDisturb, DisplayBrand, CustomerAvatar},
  data: () => ({
    areas: [],
    filterArea: null,
    brandFilter: null,
    filter: '',
    mesg: '', days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    brandId: '',
    buttons: [],
    wallets: {available: 0, transactions: []},
    send: [],
    files: [],
    addresses: [],
    wallet: {},
    whom: 'CUSTOMER',
    when: 'today',
    newCustomer: NewCustomer()
  }),
  async created () {
    await this.loadAreas()
  },
  watch: {

    async files (a, b) {
      console.log(a, b)
    },
    async customerUpdated (id) {
      let id0 = id.split(':')[0]
      console.log(`customer with id ${id} => '${id0}' is updated`)
      await this.$store.dispatch('loadSelectedCustomer', id0)
    },
  },
  computed: {
    areaSelected () {
      let a = this.areas.filter(a => a.id === this.filterArea && this.filterArea !== 'ALL')
      return a.length > 0
    },
    broadcastTitle () {
      if (this.whom === 'LEAD') return 'Message All Leads'
      let a = this.areas.filter(a => a.id === this.filterArea && this.filterArea !== 'ALL')
      if (a.length === 0) return ''
      return `Message All Customers @ ${a[0].name} `
    },
    customers () {
      return this.$store.state.searchCustomers
    },
    allBrands () {
      return [{id: null, name: 'All Brands'}, ...this.$store.state.brands]
    },

    customerUpdated () {
      return this.$store.state.customerUpdated
    },
  },
  methods: {

    async clearFiles () {
      this.files = []
    },
    async newOrder (c) {
      let addresses = (await axios.get(`/api/admin/address?customer_id=${c.id}`)).data
      let address = addresses[0]
      let ca = {id: address.id, fullAddress: address.fullAddress, person: address.person}
      let brandId = c.brand.id
      let cust = {id: c.id, name: c.name, mobile: c.mobile}
      await this.$store.dispatch('createOrder', new NewOrder(c.deliveryArea, brandId, cust, ca))
    },
    updateLocation (newP) {
      console.log('selected', newP)
      this.newCustomer.location.latitude = newP.latitude
      this.newCustomer.location.longitude = newP.longitude
    },
    displayDate (w) {
      //2022-04-03T18:22:54.05983
      return format(parseISO(w), 'yyyy-MM-dd')
    },
    async updateReviewStatus (c) {
      setTimeout(async () => {
        console.log('for customer', c.name, c.id, c.reviewDone)
        await axios.put('/api/admin/customers/review', {
          id: c.id,
          reviewDone: c.reviewDone
        })
        await this.$store.dispatch('updateReviewStatus', {id: c.id, done: c.reviewDone})
      }, 200)
    },
    async selectAll (w) {
      this.send.forEach(s => {
        s.send = w
      })
    },
    async openDetailedPage (c) {
      await this.$router.push('/admin/customer/' + c.id)
    },
    async openTel (c) {
      window.open('tel:' + c.mobile)
    },
    async createNewCustomer () {
      await this.$store.dispatch('editCustomer', JSON.stringify({id: 'NEW', at: new Date().getTime()}))
    },
    async copyToClipboard (m) {
      await navigator.clipboard.writeText(m)
    },
    async sendBroadcast () {

      let fd = new FormData()
      fd.append('area', this.filterArea)
      fd.append('mesg', this.mesg)
      fd.append('whom', this.whom)
      fd.append('when', this.when)

      this.buttons.forEach(b => {
        fd.append('buttons', b)
      })

      this.send
          .filter(s => s.send === 'true' || s.send === true)
          .forEach(d => {
            fd.append('send', d.id)
          })

      this.files.forEach(f => {
        fd.append('files', f, f.name)
      })

      await axios({
        method: 'post',
        url: '/api/admin/customers/broadcast?brand_id=' + this.brandId,
        data: fd,
        headers: {'Content-Type': 'multipart/form-data'}
      })
    },
    async messageAll () {
      if (this.filterArea === null) return
      this.send = this.customers.map(c => {
        return {
          id: c.id,
          name: c.name,
          phone: c.mobile,
          send: true
        }
      })
      this.whom = 'CUSTOMER'
      this.$bvModal.show('broadcast')
    },
    async messageAllLeads () {
      this.send = []
      this.whom = 'LEAD'
      this.$bvModal.show('broadcast')
    },
    async clearSearch () {
      this.filterArea = 'ALL'
      this.brandFilter = 'ALL'
      this.filter = ''
      await this.loadCustomers()
    },
    async loadCustomers () {
      let fa = this.filterArea === 'ALL' ? null : this.filterArea
      let fb = this.brandFilter === 'ALL' ? null : this.brandFilter
      console.log('store search', this.filter, this.filterArea, fa, this.brandFilter, fb)
      await this.$store.dispatch('searchCustomer', {search: this.filter, area: fa, brand: fb})
    },
    async editCustomer (c) {
      await this.$store.dispatch('editCustomer', JSON.stringify(c))

    },
    async createWallet (c) {
      this.newCustomer = Object.assign({}, c)
      this.wallet = {
        id: c.id,
        amount: 0,
        remarks: ''
      }
      this.$bvModal.show('modal-wallet')
    },
    async walletHistory (c) {
      this.newCustomer = Object.assign({}, c)
      this.wallets = (await axios.get('/api/admin/payments/wallets?id=' + c.id)).data
      this.$bvModal.show('modal-wallets')
    },
    async saveWallet () {
      await axios.post(`/api/admin/payments/wallets?brand_id=${this.brandId}`, this.wallet)
    },
    async loadAreas () {
      let json = await axios.get('/api/admin/areas')
      this.areas = [{id: 'ALL', displayName: 'All Areas'}, ...json.data]
      this.filterArea = 'ALL'
      await this.loadCustomers()
    },
    async save () {
      await axios.post('/api/admin/customers', this.newCustomer)
      await this.loadCustomers()
      this.newCustomer = NewCustomer()
    },
    async sendMessage (customer) {
      await this.$store.dispatch('sendMessage', customer)
    },
    async editCustomerAddress (customer) {
      await this.$store.dispatch('editAddress', {id: customer.id})
    }
  }
}
</script>
<style>
</style>