<template>
  <div class="card w-100 my-3">
    <div class="card-header" v-if="point.contact_person" style="cursor: move">
      {{ point.contact_person.name }} ({{ point.contact_person.phone }})
    </div>
    <div class="card-body d-flex flex-column flex-md-row justify-content-between align-content-center align-items-center">

      <div class="d-flex flex-column">
        <!-- Using components -->
        <b-input-group class="mt-3 mb-3" v-if="canSetManual">
          <b-form-input v-model="searchArea" placeholder="latxlng"></b-form-input>
          <b-input-group-append>
            <b-button variant="info" @click="locationSearchSelected">Set</b-button>
          </b-input-group-append>
        </b-input-group>

        <div class="mb-3 form-group">
          <google-autocomplete
              :value="description"
              @place_changed="setPlace"
              :options="ocOptions">
            <template v-slot:input="slotProps">
              <input class="form-control w-100" outlined
                     prepend-inner-icon="place"
                     placeholder="Location Of Event"
                     ref="input"
                     v-on:listeners="slotProps.listeners"
                     v-on:attrs="slotProps.attrs"/>
            </template>
          </google-autocomplete>
        </div>
        <b-form-group label="Apartment Number" class="mt-3" v-if="point.apartment_number">
          <b-form-input @update="updateStore(point)" v-model="point.apartment_number"></b-form-input>
        </b-form-group>

        <b-form-group label="Address" v-if="point.address">
          <b-form-textarea @update="updateStore(point)" v-model="point.address"></b-form-textarea>
        </b-form-group>

        <b-form-group label="Weight (in KG)" v-if="point.weight">
          <b-form-input @update="updateStore(point)" v-model="point.weight"></b-form-input>
        </b-form-group>

        <b-input-group prepend="Lat & Lng" class="mb-2" v-if="canSetManual">
          <b-form-input disabled v-model="point.latitude"></b-form-input>
          <b-form-input disabled v-model="point.longitude"></b-form-input>

        </b-input-group>
      </div>
      <div class="mb-3 mx-3">

        <google-map ref="map" :center="center"
                    :options="mapOptions"
                    :style="mapStyle">
          <google-marker :position="center"
                         :clickable="true"
                         :draggable="true"
                         @drag="updateMarker"
                         @click="updateMap"></google-marker>
        </google-map>
      </div>

    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { gmapApi } from 'vue2-google-maps'

const geo_api_key = 'eac8429d02de4d2fb544cb2fc3acaed1'
export default {
  name: 'LocationSelectGmap',
  props: {
    //['point', 'idx', 'auto']
    point: {
      type: Object
    },
    idx: {
      type: Number
    },
    manual: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 14,
      searchArea: null,
      areas: [],
      searching: false,
      locationText: 'Get My Location',
      marker: [12.898098455471294, 77.56534737671858],
      description: '',
    }
  },
  computed: {
    mapStyle(){
      const isDesktop = window.matchMedia('(min-width: 576px)').matches
      let wh = isDesktop ? 400 : 300
      return `height: ${wh}px; width: ${wh}px`
    },
    ocOptions () {
      const center = {lat: 12.972154217672621, lng: 77.59401112622677}
      // Create a bounding box with sides ~10km away from the center point
      const defaultBounds = {
        north: center.lat + 0.5,
        south: center.lat - 0.5,
        east: center.lng + 0.5,
        west: center.lng - 0.5,
      }
      return {
        bounds: defaultBounds,
        strictBounds: true,
      }
    },
    google: gmapApi,
    canSetManual () {
      return this.manual
    },
    center () {
      return {lat: this.point.latitude, lng: this.point.longitude}
    },
    mapOptions () {
      return {
        center: this.center,
        clickableIcons: false,
        streetViewControl: false,
        panControlOptions: false,
        gestureHandling: 'cooperative',
        mapTypeControl: false,
        zoomControlOptions: {
          style: 'SMALL'
        },
        zoom: this.zoom
      }
    }
  },
  methods: {
    setPlace (p) {
      console.log(p)
      this.updateMap({
            lat: p.geometry.location.lat(),
            lng: p.geometry.location.lng(),
          }
      )
      this.point.address = p.formatted_address
      this.zoom = 16
    },
    updateMarker (ev) {
      this.updateMap(
          {
            lat: ev.latLng.lat(),
            lng: ev.latLng.lng()
          }
      )
    },
    updateMap (latLng) {
      console.log(latLng)
      let newP = Object.assign({}, this.point)
      newP.latitude = latLng.lat
      newP.longitude = latLng.lng
      this.updateStore(newP)
    },
    updateStore (newP) {
      this.$store.commit('updatePoint', newP)
      this.$emit('location-selected', newP)
    },
    async locationSearchSelected () {
      let latLng = this.searchArea.split(',')
      this.updateMap({lat: parseFloat(latLng[0].trim()), lng: parseFloat(latLng[1].trim())})
    },
    async search (sp, loading) {
      if (sp.length > 5 && !this.searching) {
        loading(true)
        this.searching = true
        try {
          let url = `https://api.geoapify.com/v1/geocode/search?text=${sp},Bangalore&filter=countrycode:in&apiKey=${geo_api_key}`
          let r = await fetch(url)
          let j = await r.json()
          console.log(j)
          this.areas = j.features.filter(f => f.type === 'Feature').map(f => {
            return {
              location: [f.geometry.coordinates[0], f.geometry.coordinates[1]],
              name: `${f.properties.name}, ${f.properties.suburb}, ${f.properties.city}`
            }
          })
          loading(false)

        } catch (e) {
          console.error(e)
          loading(false)
        }
        this.searching = false

      }

    },
  },
  created () {
    setTimeout(() => {
      Vue.$gmapDefaultResizeBus.$emit('resize')
    }, 300)
  }
}
</script>
<style scoped>
.form-group {
  z-index: 3000;
}
</style>