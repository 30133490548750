<template id="admin-deliveries">
  <section>
    <form :action="deliveryAction" method="POST" target="_blank">
      <div class="d-flex justify-content-lg-between justify-content-center my-3 flex-column flex-md-row">

        <h2 class="text-center text-md-left">Deliveries</h2>
        <div class="d-flex justify-content-around justify-content-md-start align-items-center align-content-center my-3 my-md-0">
          <div class="mx-2">
            <b-form-checkbox
                switch
                size="lg"
                id="checkbox-showMap"
                v-model="showMap"
                name="checkbox-showMap"
                :value="true"
                :unchecked-value="false"
            >
              Show Map
            </b-form-checkbox>
          </div>
          <div class="mx-2">
            <b-form-checkbox
                switch
                size="lg"
                id="checkbox-showWaiting"
                v-model="showWaiting"
                name="checkbox-showWaiting"
                :value="true"
                :unchecked-value="false"
            >
              Show Waiting
            </b-form-checkbox>
          </div>
        </div>
        <b-button-group size="sm" class="my-1 my-md-0">

          <b-button @click.prevent="selectAll">
            <font-awesome-icon icon="check"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">Select All</span>
          </b-button>
          <b-button @click.prevent="deSelectAll" variant="danger">
            <font-awesome-icon icon="times"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">Remove All</span>
          </b-button>
          <b-button class="" @click.prevent="loadDeliveries" variant="primary">
            <font-awesome-icon :icon="['far','sync']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">Refresh</span>
          </b-button>
          <b-button class="" @click.prevent="makeDelivery" variant="info">
            <font-awesome-icon :icon="['far','truck']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">WeFast</span>
          </b-button>
          <b-button class="" @click.prevent="sendMessageModal" variant="success">
            <font-awesome-icon :icon="['far','envelope']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">Message</span>
          </b-button>
        </b-button-group>
      </div>
      <div class="card-columns my-3">

        <div class="card"
             v-for="o of allOrders"
             :key="o.id">

          <div class="card-header d-flex align-items-center">
            <customer-avatar :customer="o.customer" size="2rem"></customer-avatar>
            <div class="ml-2 d-flex flex-column">
              <div style="font-size: larger">{{ o.customer.name }}</div>
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <div>
                <b-badge :variant="makeVariant(o)" pill class="mr-2"> {{ o.orderStatus }}</b-badge>

              </div>

            </div>

          </div>
          <div class="card-body text-muted d-flex flex-column">

            <div class="d-flex flex-column">
              <div style="word-break: break-all" class="mb-3">
                <span class="p-1">{{ o.customerAddress.fullAddress }}</span>
              </div>
              <l-map
                  v-if="showMap"
                  style="height: 250px; width: 100%;"
                  :zoom="zoomOrder"
                  :options="inmapoption"
                  :center="makeCenter(o)">
                <l-tile-layer :url="url"></l-tile-layer>
                <l-marker :lat-lng="makeCenter(o)"></l-marker>
              </l-map>

            </div>

          </div>

          <div class="card-footer">
            <div class="d-flex justify-content-between mt-2">

              <div class="d-flex flex-column">
                <rupee>{{ o.netValue }}</rupee>
              </div>

              <div
                  class="d-flex justify-content-end flex-fill align-self-center align-content-center align-items-center"
                  style="font-size: large">
                <b-form-checkbox v-model="o.selectedForDelivery"
                                 size="lg"
                                 switch
                                 name="order"
                                 class="mr-2"
                                 :value="o.id"
                                 unchecked-value=""></b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <b-modal id="modal-message-del" title="Send Message to Customers" @ok="sendMessageOk">
      <section>

        <div class="d-flex flex-column">
          <div class="list-group">
            <div class="list-group-item list-group-item-info">Send Message To</div>
            <div class="list-group-item" v-for="o of selectedOrders" :key="o.id">
              <div class="d-flex align-content-center align-items-center">
                <customer-avatar :customer="o.customer" size="2rem"></customer-avatar>
                <div class="ml-2">{{ o.customer.name }}</div>
              </div>
            </div>

            <b-form-group label="Delayed Reason" class="mt-2">
              <b-form-input v-model="sendMessage.message"></b-form-input>
            </b-form-group>
            <b-form-group label="Next Delivery On" class="mt-2">
              <b-form-input v-model="sendMessage.whenTo"></b-form-input>
            </b-form-group>

            <b-form-group label="Brand">
              <b-form-select :options="$store.state.brands" value-field="id" text-field="name"
                             v-model="brandId"></b-form-select>
            </b-form-group>
          </div>

        </div>

      </section>
    </b-modal>
    <b-modal id="modal-wefast" title="Schedule WeFast" hide-footer size="xl">
      <section>
        <div class="row row-cols-1 row-cols-md-2">
          <div class="col">
            <b-form-group label="Date Of Pickup">
              <b-form-datepicker v-model="dateOfPickup" @input="updateDateOfPickup"/>
            </b-form-group>
          </div>
          <div class="col">
            <b-form-group label="Time Of Pickup">
              <b-form-timepicker v-model="timeOfPickup" @input="updateTimeOfPickup"/>
            </b-form-group>
          </div>
        </div>
        <small>Drag and Drop to re-arrange</small>
        <div class="d-none d-md-flex flex-wrap w-100">
          <draggable v-model="points" @dragend="updatePoints">
            <location-select-gmap v-for="p of points" :key="p.id" :point="p"/>
          </draggable>
        </div>
        <div class="d-flex d-md-none flex-wrap w-100">
          <draggable v-model="points" @dragend="updatePoints">
            <div class="list-group-item" v-for="point of points" :key="point.id">
              {{ point.contact_person.name }} ({{ point.contact_person.phone }})
            </div>
          </draggable>

          <location-select-gmap v-for="p of points" :key="p.id" :point="p"/>
        </div>
        <b-button-group class="mb-3" v-if="$store.state.wefast.points.length > 0">
          <b-button @click="checkAmount" variant="info">
            <font-awesome-icon icon="check"></font-awesome-icon>
            Check Amount
          </b-button>
          <b-button @click="makeSchedule" variant="success">
            <font-awesome-icon icon="save"></font-awesome-icon>
            Make Schedule
          </b-button>
        </b-button-group>

        <b-alert show variant="info" v-if="$store.state.wefast.points.length > 0">
          Amount for this Delivery =
          <rupee>{{ deliveryAmount }}</rupee>
        </b-alert>

      </section>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
import LocationSelectGmap from '@/components/location-select-gmap'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import CustomerAvatar from '@/components/customer-avatar'
import { format } from 'date-fns'
import draggable from 'vuedraggable'

export default {
  name: 'AdminDeliveries',
  components: {
    CustomerAvatar,
    Rupee,
    LMap,
    LTileLayer,
    LMarker,
    LocationSelectGmap,
    draggable
  },
  data: () => ({
    inmapoption: {
      zoomControl: false,
      dragging: false
    },
    deliveries: [],
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    zoom: 16,
    zoomOrder: 13,
    areas: [],
    openOrders: [],
    scheduledOrders: [],
    sendMessage: {},
    dg: true,
    showMap: false,
    showWaiting: false,
    timeOfPickup: '16:30:00',
    dateOfPickup: '',
    deliveryAmount: 0.0,
    deliveryClose: {dr: {}, remarks: [], allOk: true},
    d: {
      filter: 'PENDING',
      apartmentId: null,
      dateRange: 'MONTH'
    },
    deliveryStatusOptions: [
      {value: 'PENDING', text: 'Pending'},
      {value: 'ALL', text: 'All'}
    ],
    selectedLocation: [12.1, 77.1],
    locationTitle: '',
    brandId: '',
    points: []
  }),
  computed: {
    selectedOrders () {
      return this.allOrders.filter(o => o.selectedForDelivery)
    },
    allOrders () {
      console.log('all ord?', this.showWaiting, this.scheduledOrders)
      if (this.showWaiting) return this.scheduledOrders
      else return this.scheduledOrders.filter(o => o.orderStatus !== 'WAITING_FOR_PICKUP')
    },
    center: function () {
      if (this.selectedLocation[0] < 1)
        return [12.1, 77.1]
      else return this.selectedLocation
    },
    deliveryAction () {
      return `${process.env.VUE_APP_URL}/api/admin/deliveries/action?x-auth-token=${this.$store.state.authToken}`
    }
  },
  async created () {
    await this.loadDeliveries()
    this.timeOfPickup = this.$store.state.wefast.timeOfPickup
    this.dateOfPickup = format(new Date(), 'yyyy-MM-dd')
  },
  methods: {
    makeCenter (ord) {
      let mob = ord.customer
      let ll = mob.latLng
      return ll && ll.length === 2 ? ll : [0, 0]
    },
    selectAll () {
      if (this.showWaiting) {
        this.scheduledOrders.forEach(o => {
          this.$set(o, 'selectedForDelivery', o.id)
        })

      } else {
        this.scheduledOrders
            .filter(o => o.orderStatus === 'SCHEDULED')
            .forEach(o => {
              this.$set(o, 'selectedForDelivery', o.id)
            })

      }
    },
    deSelectAll () {
      if (this.showWaiting) {
        this.scheduledOrders.forEach(o => {
          this.$delete(o, 'selectedForDelivery')
        })

      } else {
        this.scheduledOrders
            .filter(o => o.orderStatus === 'SCHEDULED')
            .forEach(o => {
              this.$delete(o, 'selectedForDelivery')
            })

      }
    },
    makeVariant (o) {
      return this.$store.state.orderStatusMap[o.orderStatus] || 'secondary'
    },
    async loadDeliveries () {
      let json = await axios.get(`/api/admin/orders?status=SCHEDULED,WAITING_FOR_PICKUP`)
      this.scheduledOrders = json.data.orders
      console.log('orders', this.scheduledOrders)
    },
    async updatePoints () {
      this.$store.commit('createWF', {points: this.points, date: this.dateOfPickup, time: this.timeOfPickup})
    },
    async makeDelivery () {
      this.points = this.scheduledOrders
          .filter(o => o.selectedForDelivery)
          .map(o => {
            let address = o.customerAddress.fullAddress || ''

            if (address.trim().length === 0) {
              address = o.customer.address || ''

              if (address.trim().length === 0) {
                address = o.deliveryArea.address
              }
            }

            console.log('for', o.orderNum, o.customer.latLng, o.customerAddress.latLng)
            let latLng = o.customer.latLng

            function incorrectLatLng (aLatLng) {
              return !aLatLng || aLatLng.length === 0 || !aLatLng[0] || aLatLng[0] === 0
            }

            if (!incorrectLatLng(o.customerAddress.latLng)) {
              latLng = o.customerAddress.latLng
            }

            let b = incorrectLatLng(latLng)
            return {
              id: o.id,
              address: address,
              weight: '1',
              latitude: b ? 12.898098455471294 : latLng[0],
              longitude: b ? 77.56534737671858 : latLng[1],
              amount: o.netValue,
              apartment_number: o.customerAddress.door + ' ' + o.customerAddress.apartment,
              contact_person: {
                name: o.customer.name,
                phone: o.customer.mobile
              }
            }
          })

      await this.updatePoints()
      this.$bvModal.show('modal-wefast')
    },
    async updateTimeOfPickup () {
      this.$store.commit('updateTimeOfPickup', this.timeOfPickup)
    },
    async updateDateOfPickup () {
      this.$store.commit('updateDateOfPickup', this.dateOfPickup)
    },
    async checkAmount () {
      let resp = await axios.post('/api/wefast?check=true', this.$store.state.wefast)
      this.deliveryAmount = resp.data.order.payment_amount
    },
    async makeSchedule () {
      await axios.post('/api/wefast?make=true', this.$store.state.wefast)
      await this.loadDeliveries()
      this.$bvModal.hide('modal-wefast')
    },
    updateMap (ll) {
      console.log(ll)
    },
    async sendMessageModal () {

      this.$bvModal.show('modal-message-del')
    },

    async closeDelivery () {
      for (const o of this.selectedOrders) {
        await axios.put(`/api/store/orders/${o.id}?status=DELIVERED`)
      }
      await this.loadDeliveries()
    },
    async sendMessageOk () {
      this.sendMessage.orders = this.selectedOrders.map(o => o.id)
      await axios.post(`/api/admin/deliveries/message?brand_id=${this.brandId}`, this.sendMessage)
      this.$bvModal.hide('modal-message-del')
      //alert('this is disabled for now...')
    }
  }
}
</script>
<style scoped>
.w-100 div {
  width: 100%;
}
</style>
